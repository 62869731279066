<template>
  <div class="home">
    <img
      alt="checker logo"
      src="../../assets/dementia/logo_check.svg"
      class="title-logo"
    />
    <div class="white-box">
      <p class="bold-text">
        「もしかして認知症かも？」<br />「将来的に認知症になるのでは？」<br />と不安に思ったら
      </p>
      <img
        alt="checker logo"
        src="../../assets/dementia/logo_home.svg"
        class="home-logo"
      />

      <p class="bold-text">セルフチェックで分かること</p>
      <ul class="dot-list">
        <li>あなたの生活習慣や健康状態から、認知症を発症するリスク</li>
        <li>あなたの現在の状態から、病院を受診した方が良いかの目安</li>
      </ul>
      <p class="heading-text">
        今すぐ利用する
      </p>
      <router-link
        to="/profile"
        class="lineless"
        @click.native="sendGa('click', 'top', 'start_self')"
      >
        <p class="button-label activebutton">ご本人向け</p></router-link
      >
      <router-link
        to="/profile"
        class="lineless"
        @click.native="sendGa('click', 'top', 'start_family')"
      >
        <p class="button-label activebutton">
          ご家族向け
        </p></router-link
      >
      <p class="bold-text">受診の目安チェックについて</p>
      <ul class="caution-list notice-text">
        <li>
          本テストは認知症のごく初期、認知症の始まり、あるいは認知症に進展する可能性のある状態を、老年者（６０歳以上）自身が、あるいは配偶者または同居者などが簡単に予測できるように考案されたものです。
        </li>
        <li>
          本テストの結果はあくまでもおおよその目安で医学的診断に代わるものではありません。
          認知症の診断には医療機関での受診が必要です。
        </li>
      </ul>
      <p class="notice-text text-block">
        出典：大友式認知症予測テスト（公益財団法人認知症予防財団HPより）
      </p>
    </div>
    <div class="white-box">
      <p class="heading-text">
        65歳以上の高齢者のうち5人に1人が認知症
      </p>
      <ul class="dot-list">
        <li>
          認知症高齢者の数は2012年の時点で全国に約462万人と推計されています。
        </li>
        <li>
          約10年で1.5倍にも増え、2025年には700万人を超えるとの見通しです。
        </li>
        <li>
          これは、65歳以上の高齢者のうち、5人に1人が認知症に罹患する計算となります。
        </li>
      </ul>
      <br />
      <img
        alt="graph dementia"
        src="../../assets/dementia/graph_dementia.png"
        style="max-width: 330px;"
      />
      <p class="small-text" style="text-align: left;">
        出所：厚生労働省日本における認知症の高齢者人口の将来推計に関する研究(2017)
      </p>
      <p class="heading-text">
        認知症の1/3は予防可能
      </p>
      <ul class="dot-list">
        <li>
          認知症の危険因子を減らすことで、認知症の発症リスクを減らすことができます。
        </li>
      </ul>
    </div>
    <HomeTopics />
  </div>
</template>

<script>
import HomeTopics from "@/components/dementia/HomeTopics.vue";
export default {
  name: "Home",
  components: { HomeTopics },
  head: {
    title: {
      inner: "認知症かんたんチェック"
    }
  },
  beforeCreate: function() {
    this.axios
      .get(process.env.VUE_APP_API_BASE_URL + "v2/api/self_checker/user_info", {
        withCredentials: true
      })
      .then(res => {
        this.$cookies.set("patient_id", res.data.patientId, "24h");
        this.$store.commit("dementia/changeUserInfo", {
          patientId: res.data.patientId,
          userStatus: res.data.userStatus
        });
      });
  }
};
</script>

<style scoped>
@import "../../assets/style/dementia.css";

.title-logo {
  margin-top: 60px;
  margin-bottom: 20px;
}

.text-left {
  text-align: left;
}

.circle {
  float: left;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  text-align: center;
  background: #ff9900;
  line-height: 44px;
  font-weight: bold;
  font-size: 24px;
  color: #fefefe;
}

.circle-title {
  float: left;
  font-weight: bold;
  font-size: 20px;
  width: 200px;
  color: #111111;
  margin-top: 0px;
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translateY(-50%);
}

.flow-box {
  overflow: hidden;
  margin-top: -20px;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.border-box {
  border: 1px solid #000000;
  box-sizing: border-box;
  padding: 20px;
}
</style>
