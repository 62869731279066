





























































import Vue from "vue";
import { topics } from "../../data/dementia";
import rootStore from "@/store";

export default Vue.extend({
  name: "HomeTopics",
  props: [""],
  data: function() {
    return {};
  },
  methods: {},
  computed: {
    fetchTopics() {
      return [2, 3, 4].map(topicId => topics().find(t => t.id === topicId));
    }
  },
  created() {
    rootStore.commit("dementia/resetState");
  }
});
